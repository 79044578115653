import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { MainLayout, PostPage } from '../components/common'
import { MetaData } from '../components/common/meta'

import Prism from 'prismjs'
// import Img from 'gatsby-image'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.post

    useEffect(() => {
        Prism.highlightAll()
    })

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                {/*<style type="text/css">{`${post.codeinjection_styles}`}</style>*/}
            </Helmet>
            <MainLayout className={`post`}>
                <PostPage post={post} />
            </MainLayout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        post: PropTypes.object,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...PostFields
    }
  }
`
